
export default {
  data() {
    return {
      event: "Party",
      likes: 0,
    };
  },
  methods: {
    party() {
      this.likes += 1;
    },
  },
};
